import type { FormInstance } from 'antd';
import { action, observable } from 'mobx';
import type { BaseData } from '../../../../utils';
import { request } from '../../../../utils';

export default class SelectGroupModel {
  constructor({ parent }) {
    this.parent = parent;
  }

  @observable public visible = false;

  @observable public saveLoading = false; // 保存状态

  @observable public ref: FormInstance;

  @observable public id = null; // 用户id

  @observable public parent = null;

  @observable public groupInfoOptions = [];

  @action
  public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };

  @action
  public onShow = () => {
    this.visible = true;
    this.queryGroupList();
  };

  @action
  public onClose = () => {
    this.ref.resetFields();
    this.visible = false;
  };

  @action
  public queryGroupList = async (groupName = '') => {
    const req = await request<BaseData<any>>({
      url: '/quan/biz_social_group/applet/group/list',
      method: 'POST',
      data: {
        pageNum: 1,
        pageSize: 999,
        groupName,
        groupType: 2,
      },
    });
    this.groupInfoOptions = (req?.data?.list || []).map((item) => ({
      ...item,
      label: `${item.groupName}${item.userCount && Number(item.userCount) > 0 ? `（${item.userCount || 0}人）` : ''}`,
    }));
  };

  public getOptions = (): any[] => {
    const anchorGroupIdList = this.parent?.groupInfo?.map((item) => item.anchorGroupId);
    return this.groupInfoOptions?.filter((item) => !anchorGroupIdList?.includes(item.id));
  };

  @action
  public onSave = async () => {
    const values = await this.ref.validateFields();
    this.saveLoading = true;
    const anchorGroupName = this.groupInfoOptions?.filter((item) => item.id === values.anchorGroupId)[0]?.groupName || '';
    const postParams = {
      ...values,
      anchorGroupName,
    };
    setTimeout(() => {
      this.parent?.addGroup(postParams);
      this.saveLoading = false;
      this.onClose();
    }, 0);
  };
}
