import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import type { GetProp, UploadProps } from 'antd';
import { Button, Card, Col, Empty, Form, Input, Row, Select, Space, Tag, Upload, message } from 'antd';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { FullModal, singleUploadObs } from '../../../../utils';
import getImgUrl from '../../../../utils/firstFrame';
import { SelectGroupModal } from '../selectGroup';
import styles from './index.less';
import type AnchorEditModel from './model';
import { SortableList } from './sortableList';

@observer
export default class AnchorEditor extends React.Component<{ store: AnchorEditModel }> {
  render() {
    const {
      visible,
      loadingSave,
      opusListVideo,
      type,
      submitType,
      loadingVideo,
      selectGroupStore,
      groupInfo,
      parentStore,
      setOpusListVideo,
      setDeleteOpusList,
      setAddOpusList,
      setLoadingVideo,
      setType,
      setGroupInfo,
      onSave,
      onCancel,
      setRef,
    } = this?.props?.store || {};

    const maxVideoUploadNum = 4;

    return (
      <FullModal
        visible={visible}
        onCancel={onCancel}
        operation={
          <div className={styles.btnBox}>
            <Button
              loading={loadingSave}
              type="primary"
              onClick={onSave}
            >
              保存
            </Button>
          </div>
        }
        title={submitType === 'add' ? '新增兼职' : '编辑兼职'}
      >
        <div className={styles.container}>
          <Card
            title="兼职信息"
            bordered={false}
            style={{
              width: '100%',
              marginBottom: '20px',
            }}
          >
            <Form
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              ref={setRef}
            >
              <Row>
                <Col span={6}>
                  <Form.Item
                    label="兼职类型"
                    name="type"
                    validateTrigger="onChange"
                    rules={[{ required: true }]}
                  >
                    <Select
                      onChange={(value) => {
                        setType(value);
                      }}
                      options={parentStore.partTimeTypeOptions}
                      placeholder="请选择兼职类型"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="昵称"
                    name="nickName"
                    validateTrigger="onBlur"
                    rules={[{ required: true }]}
                  >
                    <Input
                      autoComplete="off"
                      placeholder="请输入昵称"
                      maxLength={99}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    label="工作年限"
                    name="seniority"
                    validateTrigger="onBlur"
                  >
                    <Select
                      style={{ width: '100%' }}
                      placeholder="请选择"
                      options={Array.from({ length: 100 }, (_, i) => ({
                        value: i,
                        label: i,
                      }))}
                    />
                  </Form.Item>
                </Col>
                {['0'].includes(type) && (
                  <Col span={6}>
                    <Form.Item
                      label="剪辑类型"
                      name="workType"
                      validateTrigger="onChange"
                      rules={[{ required: true }]}
                    >
                      <Select
                        mode="multiple"
                        maxCount={4}
                        options={parentStore.clipTypeOptions}
                        placeholder="请选择剪辑类型"
                      />
                    </Form.Item>
                  </Col>
                )}
                {['0'].includes(type) && (
                  <Col span={6}>
                    <Form.Item
                      label="擅长剪辑工具"
                      name="workTool"
                      validateTrigger="onChange"
                      rules={[{ required: true }]}
                    >
                      <Select
                        mode="multiple"
                        maxCount={4}
                        options={parentStore.clipToolOptions}
                        placeholder="请选择剪辑工具"
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Form>
          </Card>

          <Card
            title="群组信息"
            extra={
              <Button
                type="primary"
                onClick={selectGroupStore.onShow}
              >
                添加
              </Button>
            }
            bordered={false}
            style={{
              width: '100%',
              marginBottom: '20px',
            }}
          >
            {groupInfo?.length ? (
              <Space className={styles.groupInfo}>
                {groupInfo.map((item) => (
                  <Tag
                    onClose={(e) => {
                      e.preventDefault();
                      setGroupInfo(item.anchorGroupId);
                    }}
                    closable
                    key={item.anchorGroupId}
                  >
                    {item.anchorGroupName}
                  </Tag>
                ))}
              </Space>
            ) : (
              <div className={styles.empty}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            )}
          </Card>

          <Card
            title="作品信息"
            bordered={false}
            style={{ width: '100%' }}
          >
            <Row>
              <Col span={12}>
                <div className={styles.imageList}>
                  <div>
                    <SortableList
                      useDragHandle
                      axis="xy"
                      // @ts-ignore
                      type={1}
                      items={toJS(opusListVideo)}
                      onSortEnd={({ oldIndex, newIndex }) => {
                        setOpusListVideo(oldIndex, newIndex);
                      }}
                      removeFile={(url) => {
                        setDeleteOpusList(url, 1);
                      }}
                    />
                  </div>
                  <Upload
                    listType="picture-card"
                    fileList={[]}
                    accept=".mp4,.flv,.avi,.wmy,.mov,.webm,.mpeg4,.ts,.mpg,.rm,.rmvb,.mkv,.m4v"
                    beforeUpload={beforeUploadVideo}
                    maxCount={maxVideoUploadNum}
                    customRequest={async (i: any) => {
                      if (loadingVideo) {
                        return;
                      }
                      setLoadingVideo();
                      const unit = i.file.name.split(',');
                      const url = await singleUploadObs(i.file, `anchorManagement/avatar/video/${i.file.uid}${unit[unit.length - 1]}`);
                      const thumbFile = await getImgUrl(url);
                      const thumbUrl = await singleUploadObs(thumbFile, `anchorManagement/avatar/video/${thumbFile.name}`);
                      setAddOpusList(url, 1, thumbUrl);
                      setLoadingVideo();
                    }}
                    onRemove={(values) => {
                      setDeleteOpusList(values.url, 1);
                    }}
                  >
                    {opusListVideo && opusListVideo.length >= maxVideoUploadNum ? null : (
                      <button
                        style={{
                          border: 0,
                          background: 'none',
                        }}
                        type="button"
                      >
                        {loadingVideo ? <LoadingOutlined /> : <PlusOutlined />}
                        <div style={{ marginTop: 8 }}>{loadingVideo ? '上传中' : '添加视频'}</div>
                      </button>
                    )}
                  </Upload>
                </div>
                <span>支持 mp4,flv,avi,wmy,mov,webm,mpeg4,ts,mpg,rm,rmvb,mkv,m4v 格式，大小不超过 300M</span>
              </Col>
            </Row>
          </Card>
        </div>
        <SelectGroupModal store={selectGroupStore} />
      </FullModal>
    );
  }
}
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];
const beforeUploadVideo = (file: FileType) => {
  const isJpgOrPng = [
    'video/mp4',
    'video/x-flv',
    'video/avi',
    'video/x-ms-wmv',
    'video/quicktime',
    'video/webm',
    'video/mpeg',
    'video/MP2T',
    'video/mpg',
    'application/vnd.rn-realmedia',
    'application/vnd.rn-realmedia-vbr',
    'video/x-matroska',
    'video/x-m4v',
  ].includes(file.type);
  if (!isJpgOrPng) {
    message.error('仅支持上传 mp4,flv,avi,wmy,mov,webm,mpeg4,ts,mpg,rm,rmvb,mkv,m4v 格式!');
    return false;
  }
  const isLt2M = file.size / 1024 / 1024 < 300;
  if (!isLt2M) {
    message.error('视频必须小于300MB!');
    return false;
  }
  return isJpgOrPng && isLt2M;
};
