import { Button, message, Modal } from 'antd';
import dayjs from 'dayjs';
import { action, observable } from 'mobx';
import React from 'react';
import type { BaseData, CommonData, DictData, IMainSubStructureModel, NormalProgrammeParams } from '../../utils';
import { RenderByPermission, request, SearchListModal } from '../../utils';
import PartTimeDetailModel from './modal/partTimeDetail/model';
import PartTimeEditModel from './modal/partTimeEdit/model';

const { confirm } = Modal;

export default class Model {
  constructor() {
    this.getDictOptions('part_timer_type');
    this.pageStore.grid.onQuery();
  }

  public partTimeEditStore = new PartTimeEditModel(this);

  public partTimeDetailStore = new PartTimeDetailModel(this);

  @observable public partTimeTypeOptions = []; // 兼职类型

  // 剪辑类型
  @observable public clipTypeOptions = [
    {
      label: '宣传片',
      value: '宣传片',
    },
    {
      label: '广告片',
      value: '广告片',
    },
    {
      label: '视频后期',
      value: '视频后期',
    },
    {
      label: '动画特效',
      value: '动画特效',
    },
    {
      label: '配音',
      value: '配音',
    },
    {
      label: '切片',
      value: '切片',
    },
    {
      label: '短剧',
      value: '短剧',
    },
    {
      label: 'Vlog',
      value: 'Vlog',
    },
    {
      label: '美食',
      value: '美食',
    },
    {
      label: '剧情',
      value: '剧情',
    },
    {
      label: '口播',
      value: '口播',
    },
    {
      label: '混剪',
      value: '混剪',
    },
    {
      label: '三农',
      value: '三农',
    },
    {
      label: '游戏',
      value: '游戏',
    },
    {
      label: '情感',
      value: '情感',
    },
    {
      label: '音乐',
      value: '音乐',
    },
    {
      label: '电影',
      value: '电影',
    },
    {
      label: '演讲教育',
      value: '演讲教育',
    },
    {
      label: '信息流',
      value: '信息流',
    },
    {
      label: '带货',
      value: '带货',
    },
  ];

  // 剪辑工具
  @observable public clipToolOptions = [
    {
      label: 'Pr',
      value: 'Pr',
    },
    {
      label: 'Ae',
      value: 'Ae',
    },
    {
      label: 'Cad',
      value: 'Cad',
    },
    {
      label: '剪映',
      value: '剪映',
    },
    {
      label: '达芬奇',
      value: '达芬奇',
    },
    {
      label: 'Final Cut Pro',
      value: 'Final Cut Pro',
    },
    {
      label: '快影',
      value: '快影',
    },
    {
      label: '爱剪辑',
      value: '爱剪辑',
    },
    {
      label: 'C4D',
      value: 'C4D',
    },
  ];

  @observable public filterSet: Partial<NormalProgrammeParams> = {
    filterItems: [
      {
        labelWidth: 60,
        type: 'input',
        field: 'id',
        label: '用户ID',
        placeholder: '请输入',
      },
      {
        type: 'input',
        field: 'nickName',
        label: '昵称',
        placeholder: '请输入',
      },
      {
        type: 'dateRange',
        field: 'createTime',
        label: '录入时间',
      },
      {
        type: 'select',
        field: 'partTimeType',
        label: '兼职类型',
      },
    ],
  };

  public grid: IMainSubStructureModel = {
    collectData: [
      {
        name: '总量',
        value: 0,
      },
    ],
    buttons: [
      {
        text: '删除',
        permissionId: '4',
        handleClick: () => {
          const ids = Array.from(this.pageStore.grid.gridModel.selectedIds);
          if (!ids.length) {
            message.warning('请选择要删除的数据');
            return;
          }
          confirm({
            title: '删除提示',
            content: '是否删除这些数据吗？',
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
              this.requestDelete(ids.join(','));
            },
          });
        },
      },
      {
        permissionId: '2',
        text: '新增',
        handleClick: () => {
          this.partTimeEditStore.onShow();
        },
      },
    ],
    grid: {
      columns: [
        {
          key: 'userId',
          name: '操作',
          width: 200,
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');
            return (
              <div>
                <RenderByPermission permissionId={`${pageId}_1`}>
                  <Button
                    onClick={() => {
                      this.partTimeDetailStore.onShow(row);
                    }}
                    type="link"
                  >
                    详情
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_3`}>
                  <Button
                    onClick={() => {
                      this.partTimeEditStore.onShow(row, 'edit');
                    }}
                    type="link"
                  >
                    编辑
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_4`}>
                  <Button
                    onClick={() => {
                      this.onDelete(row);
                    }}
                    type="link"
                  >
                    删除
                  </Button>
                </RenderByPermission>
              </div>
            );
          },
        },
        {
          key: 'id',
          name: '用户编号',
        },
        {
          key: 'nickName',
          name: '昵称',
        },
        {
          key: 'groupInfo',
          name: '所属群组',
          formatter: ({ row }) => {
            const title = row?.groupInfo?.map((item) => `${item.anchorGroupName}(${item.anchorGroupNickName})`);
            return row?.groupInfo?.length ? <div title={title.join(' / ')}>{title.join(' / ')}</div> : '无';
          },
        },
        {
          key: 'typeName',
          name: '兼职类型',
        },
        {
          key: 'worksNum',
          name: '作品',
          formatter: ({ row }) => `${row.worksNum | 0}个`,
        },
        {
          key: 'createbyName',
          name: '录入人',
        },
        {
          key: 'createTime',
          name: '录入时间',
          formatter: ({ row }) => (row.createTime ? dayjs(row.createTime).format('YYYY-MM-DD HH:mm:ss') : ''),
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      showPager: true,
      showGridOrderNo: true,
    },
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    hiddenSubTable: true,
    api: {
      onQuery: (params) => {
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };

        for (const k in postParams) {
          if (Array.isArray(postParams[k])) {
            postParams[k] = postParams[k].toString();
          }
        }
        if (postParams.createTime) {
          postParams.createTimeBegin = postParams.createTime.split(',')[0];
          postParams.createTimeEnd = postParams.createTime.split(',')[1];
          delete postParams.createTime;
        }
        return request<BaseData<any>>({
          url: '/quan/back/biz/parttimer/list/page',
          method: 'POST',
          data: { ...postParams },
        }).then((res) => {
          this.pageStore.grid.collectData = [
            {
              name: '总量',
              value: res?.data?.total,
            },
          ];
          return res;
        });
      },
    },
  };

  public pageStore = new SearchListModal({
    programme: this.filterSet,
    grid: this.grid,
  });

  // 删除表格项
  private onDelete = (row) => {
    confirm({
      title: '删除提示',
      content: `是否删除「${row.nickName}」？`,
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        this.requestDelete(row.id);
      },
    });
  };

  // 请求删除接口
  private requestDelete = async (ids: string) => {
    const result = await request<CommonData<any>>({
      url: '/quan/back/biz/parttimer/delete',
      method: 'POST',
      data: { ids },
    });
    message.success(result.msg || '删除成功');
    this.pageStore.grid.onQuery();
  };

  // 查询字典数据
  @action
  public getDictOptions = async (dictType: string) => {
    const req = await request<CommonData<DictData[]>>({
      method: 'GET',
      url: '/quan/sys/dict/type',
      params: { dictType },
    });
    const data = (req?.data || [])?.map((item) => ({
      label: item.dictName,
      value: item.dictValue,
    }));
    switch (dictType) {
      case 'part_timer_type': {
        this.partTimeTypeOptions = data;
        this.pageStore.programme.filterItems.addDict({ partTimeType: data });
        break;
      }
    }
  };
}
