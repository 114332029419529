import React from 'react';
import { Card, Space, Descriptions, Image, Empty, Tag } from 'antd';
import type AnchorEditModel from './model';
import { FullModal } from '../../../../utils';
import { observer } from 'mobx-react';
import styles from './index.less';

@observer
export default class AnchorEditor extends React.Component<{ store: AnchorEditModel }> {
  render() {
    const { visible, opusListImage, opusListVideo, anchorInfo, groupInfo, onCancel, previewVideoOpus, setRef } = this?.props?.store || {};

    return (
      <FullModal
        onCancel={onCancel}
        visible={visible}
        title="兼职详情"
      >
        <div className={styles.container}>
          <Card
            title="兼职信息"
            bordered={false}
            style={{
              width: '100%',
              marginBottom: '20px',
            }}
          >
            {anchorInfo?.length ? (
              <Descriptions
                column={4}
                items={anchorInfo}
              />
            ) : null}
          </Card>

          <Card
            title="群组信息"
            bordered={false}
            style={{
              width: '100%',
              marginBottom: '20px',
            }}
          >
            {groupInfo?.length ? (
              <Space className={styles.groupInfo}>
                {groupInfo.map((item, index) => (
                  <Tag key={item.anchorGroupId}>
                    {item.anchorGroupName}（{item.anchorGroupNickName}）
                  </Tag>
                ))}
              </Space>
            ) : (
              <div className={styles.empty}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            )}
          </Card>

          <Card
            title="作品信息"
            bordered={false}
            style={{ width: '100%' }}
          >
            <div className={styles.opus_list}>
              {opusListImage?.length > 0 &&
                opusListImage?.map((item) => (
                  <div
                    className={styles.opus_item}
                    key={item.uid}
                    onClick={() => previewVideoOpus(item.worksUrl)}
                  >
                    <Image
                      width="100%"
                      height="100%"
                      style={{
                        objectFit: 'cover',
                        borderRadius: '4px',
                      }}
                      src={item.url}
                    />
                  </div>
                ))}
              {opusListVideo?.length > 0 &&
                opusListVideo?.map((item) => (
                  <div
                    className={styles.opus_item}
                    key={item.uid}
                    onClick={() => previewVideoOpus(item.worksUrl)}
                  >
                    <Image
                      width="100%"
                      height="100%"
                      style={{
                        objectFit: 'cover',
                        borderRadius: '4px',
                      }}
                      preview={{
                        destroyOnClose: true,
                        imageRender: () => (
                          <video
                            muted
                            style={{
                              width: '60%',
                              height: '50%',
                            }}
                            controls
                            src={item.url}
                          />
                        ),
                        toolbarRender: () => null,
                      }}
                      src={item.thumbUrl}
                    />
                  </div>
                ))}
            </div>
          </Card>
        </div>
      </FullModal>
    );
  }
}
