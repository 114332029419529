import type { DescriptionsProps, FormInstance } from 'antd';
import _ from 'lodash';
import { action, observable } from 'mobx';
import React from 'react';
import type { BaseData } from '../../../../utils';
import { request } from '../../../../utils';
import type Model from '../../model';

interface IGroup {
  anchorGroupId: number;
  anchorGroupName: string;
  anchorGroupNickName: string;
  anchorId?: number;
}

export default class AnchorEditModel {
  constructor(parentStore: Model) {
    this.parentStore = parentStore;
  }

  public parentStore: Model;

  @observable public visible = false;

  @observable public anchorId: string;

  @observable public ref: FormInstance;

  @observable public saveLoading = false;

  @observable public groupInfo: IGroup[] = []; // 群组列表

  @observable public anchorInfo: DescriptionsProps['items'] = [];

  @action public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };

  @observable public opusListVideo = [];

  @observable public opusListImage = [];

  // 打开弹窗
  @action public onShow = async (data?: any) => {
    this.visible = !this.visible;
    if (data) {
      this.anchorId = data.id;
      const req = await request<BaseData<any>>({
        method: 'GET',
        url: `/quan/back/biz/parttimer/detail/${data.id}`,
      });
      req.data.newNoteTypeName = req.data?.dictTypeVOS?.map((item) => item.defName).join('、');
      this.handleAnchorData(req.data);
      this.groupInfo = req.data?.groupInfo || [];
      this.opusListImage = (req.data?.children || [])
        ?.filter((item) => item.worksType === '0')
        ?.map((item) => ({
          uid: _.uniqueId('opusListImage_'),
          status: 'done',
          url: item.worksUrl,
        }));
      this.opusListVideo = await Promise.all(
        (req.data?.children || [])
          ?.filter((item) => item.worksType === '1')
          ?.map((item) => ({
            uid: _.uniqueId('opusListImage_'),
            status: 'done',
            url: item.worksUrl,
            thumbUrl: item.coverUrl,
          })),
      );
    }
  };

  // 关闭弹窗
  @action public onCancel = () => {
    this.visible = false;
    this.groupInfo = [];
    this.anchorId = undefined;
    this.opusListImage = [];
    this.opusListVideo = [];
    this.anchorInfo = [];
  };

  // 添加群组
  @action public addGroup = (group: any) => {
    if (!group) {
      return;
    }
    this.groupInfo.push(group);
  };

  // 预览视频作品
  public previewVideoOpus = (videoUrl: string) => {
    console.log(videoUrl);
  };

  // 处理兼职数据展示
  @action public handleAnchorData = (data) => {
    let descriptionsData: any = {
      typeName: '兼职类型',
      nickName: '昵称',
    };
    if (data?.seniority) {
      descriptionsData.seniority = '工作年限';
    }
    if (['0'].includes(data.type)) {
      descriptionsData = {
        ...descriptionsData,
        workType: '剪辑类型',
      };
    }
    if (['0'].includes(data.type)) {
      descriptionsData = {
        ...descriptionsData,
        workTool: '擅长剪辑工具',
      };
    }
    for (const key in descriptionsData) {
      if (Object.prototype.hasOwnProperty.call(descriptionsData, key)) {
        this.anchorInfo.push({
          key,
          label: descriptionsData[key],
          children: this.dataValueFormat(key, data),
        });
      }
    }
  };

  //兼职数据值格式化
  private dataValueFormat = (key: string, data: any) => {
    switch (key) {
      // 剪辑类型
      case 'workType': {
        return data?.workType?.join('、');
      }
      // 剪辑工具
      case 'workTool': {
        return data?.workTool?.join('、');
      }
      default:
        return data[key] || '';
    }
  };
}
