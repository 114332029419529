import React, { useMemo } from 'react';
import { SearchListStructure } from '../../utils/searchListStructure';
import { observer } from 'mobx-react';
import PartTimeModal from './modal/partTimeEdit';
import PartTimeDetailModal from './modal/partTimeDetail';
import Model from './model';

const PartTimeManagement: React.FC = () => {
  const store = useMemo(() => new Model(), []);
  const { pageStore, partTimeEditStore, partTimeDetailStore } = store;
  return (
    <div>
      <SearchListStructure store={pageStore} />
      <PartTimeModal store={partTimeEditStore} />
      <PartTimeDetailModal store={partTimeDetailStore} />
    </div>
  );
};
export default observer(PartTimeManagement);
