import type { FormInstance } from 'antd';
import { message } from 'antd';
import _ from 'lodash';
import { action, observable, toJS } from 'mobx';
import type { BaseData } from '../../../../utils';
import { request } from '../../../../utils';
import type Model from '../../model';
import SelectGroupModel from '../selectGroup/model';

interface IGroup {
  anchorGroupId: number | string;
  anchorGroupName: string;
  anchorGroupNickName: string;
  anchorId?: number | string;
}

export default class AnchorEditModel {
  constructor(parentStore: Model) {
    this.parentStore = parentStore;
    this.selectGroupStore = new SelectGroupModel({ parent: this });
  }

  @observable public loading = false;

  @observable public loadingSave = false;

  @observable public loadingVideo = false;

  @action
  public setLoadingVideo = () => {
    this.loadingVideo = !this.loadingVideo;
  };

  @observable public selectGroupStore;

  @observable public visible = false;

  @observable public submitType = 'add';

  @observable public editId: string; //编辑id

  @observable public ref: FormInstance;

  @observable public saveLoading = false;

  @observable public groupInfo: IGroup[] = []; // 群组列表

  @observable
  public type: string; // 兼职类型

  @action
  public setType = (values: string) => {
    this.ref.resetFields();
    this.type = values;
    this.ref.setFieldsValue({ type: values });
  };

  @action
  public setGroupInfo = (anchorGroupId: string | number) => {
    this.groupInfo = this.groupInfo.filter((item: any) => item.anchorGroupId !== anchorGroupId);
  };

  @observable public opusListImage: any[] = []; // 图片作品列表

  @observable public opusListVideo: any[] = []; // 视频作品列表

  @action
  public setAddOpusList = (url: string, type: 0 | 1, thumbUrl?: string) => {
    if (type === 0) {
      this.opusListImage = [
        ...this.opusListImage,
        {
          uid: _.uniqueId('opusListImage_'),
          status: 'done',
          url,
        },
      ];
      return;
    }
    this.opusListVideo = [
      ...this.opusListVideo,
      {
        uid: _.uniqueId('opusListVideo_'),
        status: 'done',
        url,
        thumbUrl,
      },
    ];
  };

  @action
  public setDeleteOpusList = (url: string, type: 0 | 1) => {
    if (type === 0) {
      this.opusListImage = this.opusListImage.filter((item: any) => item.url !== url);
      return;
    }
    this.opusListVideo = this.opusListVideo.filter((item: any) => item.url !== url);
  };

  @action public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };

  // 打开弹窗
  @action public onShow = async (data?: any, submitType = 'add') => {
    this.visible = !this.visible;
    if (data) {
      this.submitType = submitType;
      this.editId = data.id;
      const req = await request<BaseData<any>>({
        method: 'GET',
        url: `/quan/back/biz/parttimer/detail/${data.id}`,
      });
      const { type, children, nickName, groupInfo, workType, workTool, seniority } = req.data || {};
      this.setType(type?.toString());
      this.ref.setFieldsValue({
        type: type?.toString(),
        nickName,
        workType,
        workTool,
        seniority,
      });
      this.groupInfo = groupInfo || [];
      this.opusListVideo = await Promise.all(
        (children || [])
          ?.filter((item) => item.worksType === '1')
          ?.map((item) => ({
            uid: _.uniqueId('opusListVideo_'),
            status: 'done',
            url: item.worksUrl,
            thumbUrl: item.coverUrl,
          })),
      );
    }
  };

  // 关闭弹窗
  @action public onCancel = () => {
    this.visible = false;
    this.ref?.resetFields();
    this.groupInfo = [];
    this.opusListVideo = [];
    this.submitType = 'add';
    this.type = '';
  };

  // 添加群组
  @action public addGroup = (group: any) => {
    if (!group) {
      return;
    }
    this.groupInfo.push(group);
  };

  // 保存兼职信息
  @action public onSave = async () => {
    const isValidated = await this.ref.validateFields();
    if (!isValidated) {
      return;
    }
    if (this.loadingSave) {
      return;
    }
    this.loadingSave = true;
    // 获取表单值
    const values = this.ref.getFieldsValue();
    const postParams = {
      ...values,
      groupInfo: this.groupInfo,
      children: [],
    };
    const children = [];
    this.opusListVideo?.forEach((item, index) => {
      children.push({
        worksType: '1',
        worksUrl: item.url,
        coverUrl: item.thumbUrl,
        sortFlag: index + 1,
      });
    });
    postParams.children = children;
    if (this.submitType === 'edit') {
      postParams.id = this.editId;
    }
    const apiUrl = this.submitType === 'add' ? '/quan/back/biz/parttimer/add' : '/quan/back/biz/parttimer/edit';
    request<{ code: number; msg?: string; data: any }>({
      url: apiUrl,
      method: 'POST',
      data: { ...postParams },
    })
      .then((result) => {
        message.success(result.msg || '操作成功');
        this.ref.resetFields();
        this.onCancel();
        this.parentStore?.pageStore?.grid?.onQuery();
      })
      .finally(() => {
        this.loadingSave = false;
      });
  };

  public parentStore: Model;

  @action
  public setOpusListImage = (oldIndex: number, newIndex: number) => {
    if (oldIndex === newIndex) {
      return;
    }
    this.opusListImage = moveItem(toJS(this.opusListImage), oldIndex, newIndex);
  };

  @action
  public setOpusListVideo = (oldIndex: number, newIndex: number) => {
    if (oldIndex === newIndex) {
      return;
    }
    this.opusListVideo = moveItem(toJS(this.opusListVideo), oldIndex, newIndex);
  };
}

const moveItem = (arr, fromIndex, toIndex) => {
  if (fromIndex < 0 || fromIndex >= arr.length || toIndex < 0 || toIndex >= arr.length) {
    throw new Error('Index out of bounds');
  }
  const newArr = [...arr];
  const [movedItem] = newArr.splice(fromIndex, 1); // 从 fromIndex 移除元素，并获取该元素
  newArr.splice(toIndex, 0, movedItem);
  return newArr;
};
